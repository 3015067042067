import '@rainbow-me/rainbowkit/styles.css'

import { ResetCSS, ScrollToTopButtonV2, ToastListener } from '@pancakeswap/uikit'
import { Chain, RainbowKitProvider, getDefaultWallets } from '@rainbow-me/rainbowkit'
import BigNumber from 'bignumber.js'
import { SentryErrorBoundary } from 'components/ErrorBoundary'
import GlobalCheckClaimStatus from 'components/GlobalCheckClaimStatus'
import { PageMeta } from 'components/Layout/Page'
import { FixedSubgraphHealthIndicator } from 'components/SubgraphHealthIndicator/FixedSubgraphHealthIndicator'
import TransactionsDetailModal from 'components/TransactionDetailModal'
import { useAccountEventListener } from 'hooks/useAccountEventListener'
import useEagerConnect from 'hooks/useEagerConnect'
import useEagerConnectMP from 'hooks/useEagerConnect.bmp'
import useLockedEndNotification from 'hooks/useLockedEndNotification'
import useSentryUser from 'hooks/useSentryUser'
import useThemeCookie from 'hooks/useThemeCookie'
import useUserAgent from 'hooks/useUserAgent'
import { NextPage } from 'next'
import { DefaultSeo } from 'next-seo'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Script from 'next/script'
import { Fragment } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, useStore } from 'state'
import { usePollBlockNumber } from 'state/block/hooks'
import { WagmiConfig, configureChains, createConfig } from 'wagmi'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { Blocklist, Updaters } from '..'
import { SEO } from '../../next-seo.config'
import Providers from '../Providers'
import Menu from '../components/Menu'
import GlobalStyle from '../style/Global'

const EasterEgg = dynamic(() => import('components/EasterEgg'), { ssr: false })

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  usePollBlockNumber()
  useEagerConnect()
  useUserAgent()
  useAccountEventListener()
  useSentryUser()
  useThemeCookie()
  useLockedEndNotification()
  return null
}

function MPGlobalHooks() {
  usePollBlockNumber()
  useEagerConnectMP()
  useUserAgent()
  useAccountEventListener()
  useSentryUser()
  useLockedEndNotification()
  return null
}

export const ecredits: Chain = {
  id: 63000,
  name: 'eCredits',
  network: 'eCredits',
  iconUrl: 'https://docs.ecredits.com/img/small_e.png',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'ECS',
    symbol: 'ECS',
  },
  rpcUrls: {
    public: { http: ['https://rpc.ecredits.com'] },
    default: { http: ['https://rpc.ecredits.com'] },
  },
  blockExplorers: {
    default: { name: 'EcreditExplorer', url: 'https://explorer.ecredits.com/' },
    etherscan: { name: 'EcreditExplorer', url: 'https://explorer.ecredits.com/' },
  },
  contracts: {
    multicall3: {
      address: '0xfC32609574C6119d7f7b3d8AC4795CB60D974912',
      blockCreated: 16236195,
    },
  },
}

export const ecreditsTestnet: Chain = {
  id: 63001,
  name: 'eCreditsTestnet',
  network: 'ecreditsTestnet',
  iconUrl: 'https://docs.ecredits.com/img/small_e.png',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'ECS',
    symbol: 'ECS',
  },
  rpcUrls: {
    public: { http: ['https://rpc.tst.ecredits.com'] },
    default: { http: ['https://rpc.tst.ecredits.com'] },
  },
  blockExplorers: {
    default: { name: 'EcreditExplorer', url: 'https://explorer.tst.ecredits.com/' },
    etherscan: { name: 'EcreditExplorer', url: 'https://explorer.tst.ecredits.com/' },
  },
  contracts: {
    multicall3: {
      address: '0x375bCe1999Ed3F0418AACAE9a0934f2D3637f7Ad',
      blockCreated: 12983140,
    },
  },
  testnet: true,
}

function MyApp(props: AppProps<{ initialReduxState: any; dehydratedState: any }>) {
  const { pageProps, Component } = props
  const store = useStore(pageProps.initialReduxState)

  const { chains, publicClient } = configureChains(
    [ecredits, ecreditsTestnet],
    [alchemyProvider({ apiKey: process.env.NEXT_PUBLIC_ALCHEMY_ID }), publicProvider()],
  )

  const { connectors } = getDefaultWallets({
    appName: 'My RainbowKit App',
    projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
    chains,
  })

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  })

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains} initialChain={63000}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
          />
          <meta
            name="description"
            content="Cheaper and faster than Uniswap? Discover PancakeSwap, the leading DEX on BNB Smart Chain (BSC) with the best farms in DeFi and a lottery for CAKE."
          />
          <meta name="theme-color" content="#1FC7D4" />
          {(Component as NextPageWithLayout).mp && (
            // eslint-disable-next-line @next/next/no-sync-scripts
            <script src="https://public.bnbstatic.com/static/js/mp-webview-sdk/webview-v1.0.0.min.js" id="mp-webview" />
          )}
        </Head>
        <DefaultSeo {...SEO} />
        <Providers store={store} dehydratedState={pageProps.dehydratedState}>
          <PageMeta />
          {(Component as NextPageWithLayout).Meta && (
            // @ts-ignore
            <Component.Meta {...pageProps} />
          )}
          <Blocklist>
            {(Component as NextPageWithLayout).mp ? <MPGlobalHooks /> : <GlobalHooks />}
            <ResetCSS />
            <GlobalStyle />
            <GlobalCheckClaimStatus excludeLocations={[]} />
            <PersistGate loading={null} persistor={persistor}>
              <Updaters />
              <App {...props} />
            </PersistGate>
          </Blocklist>
        </Providers>
        <Script
          strategy="afterInteractive"
          id="google-tag"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_NEW_GTAG}');
          `,
          }}
        />
      </RainbowKitProvider>
    </WagmiConfig>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC<React.PropsWithChildren<unknown>>
  /** render component without all layouts */
  pure?: true
  /** is mini program */
  mp?: boolean
  /**
   * allow chain per page, empty array bypass chain block modal
   * @default [ChainId.BSC]
   * */
  chains?: number[]
  isShowScrollToTopButton?: true
  /**
   * Meta component for page, hacky solution for static build page to avoid `PersistGate` which blocks the page from rendering
   */
  Meta?: React.FC<React.PropsWithChildren<unknown>>
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? SentryErrorBoundary : Fragment

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  if (Component.pure) {
    return <Component {...pageProps} />
  }

  // Use the layout defined at the page level, if available
  const Layout = Component.Layout || Fragment
  const ShowMenu = Component.mp ? Fragment : Menu
  const isShowScrollToTopButton = Component.isShowScrollToTopButton || true

  return (
    <ProductionErrorBoundary>
      <ShowMenu>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ShowMenu>
      <EasterEgg iterations={2} />
      <ToastListener />
      <FixedSubgraphHealthIndicator />
      {/* <NetworkModal pageSupportedChains={Component.chains} /> */}
      <TransactionsDetailModal />
      {isShowScrollToTopButton && <ScrollToTopButtonV2 />}
    </ProductionErrorBoundary>
  )
}

export default MyApp
