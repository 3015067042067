import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 96 96" {...props}>
      <g transform="translate(0.000000,105.000000) scale(0.0500000,-0.0500000)" fill="#0033ff" stroke="none">
        <path
          d="M1115 1804 c-96 -14 -259 -78 -319 -125 -30 -24 -34 -64 -11 -108 17
          -33 32 -41 88 -42 l42 -1 -50 -9 c-62 -10 -94 -45 -96 -104 l-2 -40 -6 48 c-8
          58 -33 84 -88 93 -40 6 -42 5 -77 -42 -45 -62 -94 -178 -113 -269 -23 -106
          -13 -267 22 -372 42 -125 39 -123 121 -86 215 98 538 174 731 173 47 -1 45 -2
          -62 -39 -224 -77 -409 -172 -573 -293 l-54 -40 29 -27 c92 -87 251 -171 371
          -196 196 -40 414 6 587 125 95 65 204 199 250 305 21 48 55 164 55 185 0 29
          -299 108 -508 134 -197 25 -502 16 -657 -19 -49 -11 110 58 207 89 280 93 645
          128 893 87 28 -5 52 -7 54 -5 10 10 -62 181 -102 242 -42 63 -128 154 -187
          201 -40 30 -142 79 -222 107 -56 19 -99 26 -183 29 -60 2 -123 1 -140 -1z
          m-237 -422 c-8 -9 -14 -9 -24 -1 -10 8 -10 14 -1 23 16 18 40 -3 25 -22z
          m-175 -37 l21 -15 -21 -15 c-12 -8 -24 -25 -26 -38 -4 -19 -4 -18 -6 5 0 15
          -9 32 -18 38 -17 10 -17 10 0 20 9 6 18 23 18 38 2 23 2 24 6 5 2 -13 14 -30
          26 -38z"
        />
        <path
          d="M695 1594 c-54 -54 -57 -59 -36 -62 40 -6 79 17 91 54 23 69 9 71
          -55 8z"
        />
      </g>
    </Svg>
  );
};

export default Icon;
