import { ChainId, chainNames } from '@pancakeswap/chains'
import memoize from 'lodash/memoize'
import { defineChain } from 'viem'
import {
  Chain,
  arbitrum,
  arbitrumGoerli,
  base,
  baseGoerli,
  bscTestnet,
  bsc as bsc_,
  goerli,
  linea,
  lineaTestnet,
  mainnet,
  opBNB,
  opBNBTestnet,
  polygonZkEvm,
  polygonZkEvmTestnet,
  scrollSepolia,
  zkSync,
  zkSyncTestnet,
} from 'wagmi/chains'

export const CHAIN_QUERY_NAME = chainNames

const CHAIN_QUERY_NAME_TO_ID = Object.entries(CHAIN_QUERY_NAME).reduce((acc, [chainId, chainName]) => {
  return {
    [chainName.toLowerCase()]: chainId as unknown as ChainId,
    ...acc,
  }
}, {} as Record<string, ChainId>)

export const getChainId = memoize((chainName: string) => {
  if (!chainName) return undefined
  return CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()] ? +CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()] : undefined
})

const bsc = {
  ...bsc_,
  rpcUrls: {
    ...bsc_.rpcUrls,
    public: {
      ...bsc_.rpcUrls.public,
      http: ['https://bsc-dataseed.binance.org/'],
    },
    default: {
      ...bsc_.rpcUrls.default,
      http: ['https://bsc-dataseed.binance.org/'],
    },
  },
} satisfies Chain

export const ecredits = defineChain({
  id: 63000,
  name: 'eCredits',
  network: 'ecredits',
  nativeCurrency: {
    decimals: 18,
    name: 'ECS',
    symbol: 'ECS',
  },
  rpcUrls: {
    public: { http: ['https://rpc.ecredits.com'] },
    default: { http: ['https://rpc.ecredits.com'] },
  },
  blockExplorers: {
    default: { name: 'EcreditExplorer', url: 'https://explorer.ecredits.com' },
    etherscan: { name: 'EcreditExplorer', url: 'https://explorer.ecredits.com' },
  },
  contracts: {
    multicall3: {
      address: '0xfC32609574C6119d7f7b3d8AC4795CB60D974912',
      blockCreated: 12983140,
    },
  },
  testnet: false,
})

export const ecreditsTestnet = defineChain({
  id: 63001,
  name: 'eCreditsTestnet',
  network: 'eCreditsTestnet',
  nativeCurrency: {
    decimals: 18,
    name: 'ECS',
    symbol: 'ECS',
  },
  rpcUrls: {
    public: { http: ['https://rpc.tst.ecredits.com'] },
    default: { http: ['https://rpc.tst.ecredits.com'] },
  },
  blockExplorers: {
    default: { name: 'EcreditExplorer', url: 'https://explorer.tst.ecredits.com' },
    etherscan: { name: 'EcreditExplorer', url: 'https://explorer.tst.ecredits.com' },
  },
  contracts: {
    multicall3: {
      address: '0x375bCe1999Ed3F0418AACAE9a0934f2D3637f7Ad',
      blockCreated: 12983140,
    },
  },
  testnet: true,
})

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS: ChainId[] = [
  ChainId.ARBITRUM_ONE,
  ChainId.ARBITRUM_GOERLI,
  ChainId.POLYGON_ZKEVM,
  ChainId.POLYGON_ZKEVM_TESTNET,
  ChainId.ZKSYNC,
  ChainId.ZKSYNC_TESTNET,
  ChainId.LINEA_TESTNET,
  ChainId.LINEA,
  ChainId.BASE,
  ChainId.BASE_TESTNET,
  ChainId.OPBNB,
  ChainId.OPBNB_TESTNET,
]

export const CHAINS = [
  ecredits,
  ecreditsTestnet,
  bsc,
  mainnet,
  bscTestnet,
  goerli,
  polygonZkEvm,
  polygonZkEvmTestnet,
  zkSync,
  zkSyncTestnet,
  arbitrum,
  arbitrumGoerli,
  linea,
  lineaTestnet,
  arbitrumGoerli,
  arbitrum,
  base,
  baseGoerli,
  opBNB,
  opBNBTestnet,
  scrollSepolia,
]
