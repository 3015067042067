import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 600.000000 300.000000" {...props}>
      <g transform="translate(0.000000,470.000000) scale(0.310000, -0.310000)" fill="#0033ff" stroke="none">
        <path
          d="M1005 1464 c-117 -57 -252 -98 -390 -119 -85 -13 -79 4 -72 -190 4
            -95 14 -210 23 -256 18 -94 61 -239 72 -239 4 0 57 18 117 39 166 59 447 112
            580 110 35 0 27 -5 -70 -38 -143 -49 -197 -72 -296 -121 -87 -44 -249 -145
            -249 -156 0 -19 99 -141 167 -204 82 -77 185 -150 212 -150 27 0 142 83 222
            161 131 128 236 308 284 488 25 97 53 297 42 308 -4 3 -91 8 -194 11 -172 4
            -355 -9 -473 -33 -14 -3 4 9 40 26 134 64 368 129 541 151 l99 12 0 32 c0 38
            3 37 -130 59 -108 18 -237 59 -331 105 -104 51 -95 51 -194 4z m-223 -227 c12
            -32 41 -46 96 -48 34 -1 33 -2 -13 -10 -64 -10 -94 -43 -96 -102 l-2 -42 -6
            41 c-10 62 -34 92 -78 99 -66 11 -69 13 -25 14 58 1 101 42 103 98 2 33 3 34
            6 8 2 -16 9 -43 15 -58z m96 -195 c-8 -9 -14 -9 -24 -1 -11 9 -11 15 -3 25 8
            10 14 11 24 1 9 -8 10 -16 3 -25z m-175 -37 l21 -15 -21 -15 c-12 -8 -24 -25
            -26 -38 -4 -19 -4 -18 -6 5 0 15 -9 32 -18 38 -17 10 -17 10 0 20 9 6 18 23
            18 38 2 23 2 24 6 5 2 -13 14 -30 26 -38z"
        />
        <path
          d="M3353 1090 c-45 -9 -86 -29 -119 -58 -74 -63 -102 -248 -62 -408 12
          -52 59 -110 106 -131 48 -22 210 -22 267 -1 l40 15 3 63 c3 61 3 62 -20 56
          -13 -4 -62 -10 -110 -13 -71 -5 -92 -3 -112 11 -35 22 -49 83 -44 194 4 85 6
          95 31 120 26 25 33 27 137 30 l110 4 0 58 c0 51 -2 58 -22 64 -32 8 -154 6
          -205 -4z"
        />
        <path
          d="M3783 1091 c-94 -24 -138 -86 -138 -192 0 -112 24 -136 175 -174 84
            -21 85 -22 88 -54 6 -57 -10 -64 -118 -58 -52 4 -107 9 -122 13 l-28 6 0 -66
            c0 -63 1 -67 28 -78 15 -6 79 -11 142 -12 105 -1 119 1 160 24 83 46 115 168
            71 266 -21 47 -40 57 -169 88 -85 21 -87 22 -90 53 -3 25 2 35 23 49 21 14 41
            16 115 11 l90 -6 0 59 c0 32 -5 62 -10 65 -17 10 -183 15 -217 6z"
        />
        <path
          d="M2110 784 l0 -306 148 5 c173 6 224 20 276 77 52 57 69 128 64 259
            -8 224 -71 271 -365 271 l-123 0 0 -306z m290 158 c46 -23 63 -79 56 -180 -7
            -115 -35 -142 -147 -142 l-59 0 0 170 0 170 58 0 c34 0 71 -7 92 -18z"
        />
        <path
          d="M2690 785 l0 -305 200 0 200 0 0 70 0 70 -130 0 -130 0 0 50 0 50
            110 0 110 0 0 65 0 65 -112 0 -113 0 3 55 4 55 124 0 124 0 0 65 0 65 -195 0
            -195 0 0 -305z"
        />
      </g>
    </Svg>
  );
};

export default Logo;
